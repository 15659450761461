<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->

<template>
	<ManageYourAccountTemplate v-if="!$store.state.loading" :full-width="editMode">
		<template v-if="!editMode && securityPolicy.updateBanking">
			<BRow class="directDepositCard">
				<BCol>
					<template v-if="!reviewMode">
						<h1>{{ $t('title.initial') }}</h1>
					</template>
					<template v-else>
						<h1>{{ $t('title.reviewMode') }}</h1>
						<p class="grey-text">{{ $t('title.subtitle') }}</p>
					</template>
				</BCol>
			</BRow>
			<BRow>
				<BCol>
					<DirectDepositCard
						id="DirectDepositCard"
						:direct-deposit-info="directDepositInfo"
						automation-id="directDepositCard"
						:edit-mode="editMode"
						:review-mode="reviewMode"
						:update-banking-allowed="securityPolicy.updateBanking"
						@input="updateDirectDepositInfo"
						@edit="openEditView()"
						@save="saveDirectDepositInfo()"
					/>
				</BCol>
			</BRow>
		</template>
		<template v-else>
			<BRow>
				<BCol v-if="securityPolicy.updateBanking" cols="12" md="6">
					<h1>{{ $t('title.editMode') }}</h1>
					<p class="grey-text font-size-21">{{ $t('label.addBankingInfo') }}</p>
					<p>
						<b>{{ $t('label.twoWays') }}</b>
					</p>
					<ul>
						<li class="grey-text">{{ $t('label.numberOne') }}</li>
						<li class="grey-text">
							{{ $t('label.numberTwo') }}
							<a
								href="#"
								class="btn-link p-0"
								role="button"
								automation-id="goToDDForm"
								@click="downloadForm()"
								>{{ $t('label.numberThree') }}</a
							>
							{{ $t('label.numberFour') }}
						</li>
					</ul>
					<BRow class="d-sm-block d-md-none">
						<BCol cols="12" lg="10" class="mb-5">
							<img
								:src="require(`@/assets/${imageInfo}`)"
								:alt="$t('imgAlt')"
								automation-id="directDepositImage'"
							/>
						</BCol>
					</BRow>
					<DirectDepositCard
						id="DirectDepositCard"
						:direct-deposit-info="directDepositInfo"
						automation-id="directDeposit"
						:edit-mode="editMode"
						:data-exists="dataExists"
						class="mb-0"
						@input="updateDirectDepositInfo"
						@edit="openEditView()"
						@review="submitReview()"
						@cancel="cancel()"
					/>
					<template v-if="dataExists">
						<div class="opt-out">
							<BaseButton
								slot="append"
								:label="$t('label.cancelDD')"
								variant="link"
								class="p-0 m-0 form-button"
								automation-id="cancelDirectDeposit"
								@click="cancelDD()"
							/>
						</div>
					</template>
				</BCol>
				<BCol v-else cols="12" md="6">
					<h1>{{ $t('title.editMode') }}</h1>
					<DirectDepositCard
						v-if="directDepositInfo.account"
						id="DirectDepositCard"
						:direct-deposit-info="directDepositInfo"
						automation-id="directDepositCard"
						:update-banking-allowed="securityPolicy.updateBanking"
						:viewing-mode="true"
					/>
					<UpdateFormCard
						:title="$t('noUpdateBankingCard.title')"
						:sub-title="$t('noUpdateBankingCard.subTitle')"
						:button-label="$t('noUpdateBankingCard.button')"
						automation-id="bankingInformationForm"
						@click="downloadForm()"
					/>
					<BRow class="d-sm-block d-md-none">
						<BCol cols="12" lg="10" class="mb-5">
							<img
								:src="require(`@/assets/${imageInfo}`)"
								:alt="$t('imgAlt')"
								automation-id="directDepositImage'"
							/>
						</BCol>
					</BRow>
				</BCol>
				<BCol cols="12" md="6" class="mb-5 pl-md-5">
					<BRow class="d-none d-md-block">
						<BCol cols="12" lg="10" class="mb-5">
							<img
								:src="require(`@/assets/${imageInfo}`)"
								:alt="$t('imgAlt')"
								automation-id="directDepositImage'"
							/>
						</BCol>
					</BRow>
					<p class="h4">{{ $t('label.noCheque') }}</p>
					<!-- Part of the sentence requires bolding easier for maintenance to put <b> tag inside i18n section -->
					<!-- eslint-disable-next-line @intlify/vue-i18n/no-v-html eslint-disable-next-line vue/no-v-html -->
					<p class="grey-text" v-html="$t('label.whereInfoIs')" />
				</BCol>
			</BRow>
		</template>
		<ErrorModal
			:modal-show="isCancelModalVisible"
			class="error-modal"
			automation-id="cancelModal"
			aria-modal="true"
			:has-close-button="true"
			:error-title="$t('modal.title')"
			:error-message="$t('modal.paragraph')"
			@change="closeModal"
		>
			<BaseButton
				:label="$t('modal.button')"
				class="m-0 modal_button"
				automation-id="cancelDirectDeposit"
				@click="cancelDirectDepositForSure()"
			></BaseButton>

			<p class="pb-0 mb-0 update-text">
				{{ $t('modal.paragraphTwo')
				}}<BaseButton
					slot="append"
					:label="$t('modal.paragraphThree')"
					variant="link"
					class="pl-0 m-0 update-button"
					automation-id="goBackToEdit"
					@click="openEditView()"
				/>
			</p>
		</ErrorModal>
	</ManageYourAccountTemplate>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import BreadcrumbsManager from '@/mixins/BreadcrumbsManager';
import * as Routes from '@/constants/Routes.js';
import { BRow, BCol } from 'bootstrap-vue';
import DirectDepositInfoCall from '@/models/DirectDepositInfoCall.js';
import DirectDepositCard from '@/components/manage-your-account/direct-deposit/DirectDepositCard';
import BaseButton from '@/components/common/base/BaseButton.vue';
import SecurityPolicy from '@/models/SecurityPolicy';
import Forms from '@/models/Forms';
import ManageYourAccountTemplate from '@/pages/manage-your-account/ManageYourAccountTemplate.vue';
import UpdateFormCard from '@/components/common/card/UpdateFormCard';
import ErrorModal from '@/components/common/ErrorModal';

/**
 * This component is responsible to render a member table.
 */
// @vue/component
@Component({
	name: 'DirectDepositPage',
	components: {
		ManageYourAccountTemplate,
		DirectDepositCard,
		BRow,
		BCol,
		BaseButton,
		ErrorModal,
		UpdateFormCard
	},
	mixins: [BreadcrumbsManager]
})
export default class DirectDepositPage extends Vue {
	error = {
		title: '',
		body: ''
	};
	directDepositInfo = {
		bank: '',
		account: '',
		branch: ''
	};
	editMode = false;
	reviewMode = false;
	dataExists = false;
	isModalVisible = false;
	isCancelModalVisible = false;
	securityPolicy = {
		demo: false,
		forms: false,
		banking: false,
		updateMemberProfile: false,
		submitClaim: false,
		easyHsa: false,
		analytics: false,
		benefitUsage: false,
		coverage: false,
		memberProfile: false,
		statements: false,
		rta: false,
		drugAdherence: false,
		suppressCoverage: false,
		filterOnKeywords: false,
		updateBanking: false,
		storeFront: false,
		premiumPayment: false,
		beneficiary: false,
		premiums: false
	};

	// Set the path for the BreadCrumbs Manager.
	created() {
		this.setBreadcrumbPath(
			[
				{ text: this.$t('breadcrumb.home', 'en'), to: { name: Routes.DASHBOARD } },
				{ text: this.$t('breadcrumb.memberInfo', 'en'), to: { name: Routes.MANAGE_YOUR_ACCOUNT } },
				{ text: this.$t('breadcrumb.directDeposit', 'en') }
			],
			[
				{ text: this.$t('breadcrumb.home', 'fr'), to: { name: Routes.DASHBOARD } },
				{ text: this.$t('breadcrumb.memberInfo', 'fr'), to: { name: Routes.MANAGE_YOUR_ACCOUNT } },
				{ text: this.$t('breadcrumb.directDeposit', 'fr') }
			]
		);
	}

	/**
	 * before creating the component call the API to get the information to display on the member card.
	 */
	async beforeCreate() {
		const tempSecurityPolicy = await SecurityPolicy.getSecurityPolicy(
			sessionStorage.getItem('email')
		);
		if (tempSecurityPolicy === null) {
			this.alreadyGotSecurityPolicy = true;
			await SecurityPolicy.getUserSecurityPolicies(
				sessionStorage.getItem('email'),
				sessionStorage.getItem('apiToken'),
				this.$root.$i18n.locale
			);
			this.securityPolicy = await SecurityPolicy.getSecurityPolicy(sessionStorage.getItem('email'));
		} else {
			this.securityPolicy = tempSecurityPolicy;
		}
		this.$store.dispatch('updateLoading', true);
		DirectDepositInfoCall.getDirectDepositInfo(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$store.state.i18n.locale
		).then((response) => {
			this.$store.dispatch('updateLoading', false);
			if (response) {
				if (response.status === 201 || response.status === 200 || response.status === 204) {
					this.directDepositInfo = response.data;
					if (this.directDepositInfo === '') {
						this.directDepositInfo = {
							bank: '',
							account: '',
							branch: ''
						};
						this.editMode = true && this.securityPolicy.updateBanking;
						this.dataExists = false;
					} else {
						this.dataExists = true;
					}
					this.$store.dispatch('clearError');
				} else {
					this.securityPolicy.updateBanking = false;
				}
			}
		});
	}

	/**
	 * Method calls the Api to get the member card pdf,
	 * Then calls another method to download it for the member.
	 */
	openEditView() {
		this.$store.dispatch('successBanner', { show: false });
		if (this.isCancelModalVisible) {
			this.isCancelModalVisible = false;
			this.editMode = true && this.securityPolicy.updateBanking;
		} else {
			this.editMode = !this.editMode && this.securityPolicy.updateBanking;
		}
	}
	updateDirectDepositInfo(value) {
		this.directDepositInfo = value;
	}
	submitReview() {
		this.editMode = false;
		this.reviewMode = true && this.securityPolicy.updateBanking;
	}
	cancel() {
		this.reviewMode = false;
		this.editMode = false;
		this.directDepositInfo = {
			bank: '',
			account: '',
			branch: ''
		};
		this.$store.dispatch('updateLoading', true);
		DirectDepositInfoCall.getDirectDepositInfo(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$store.state.i18n.locale
		).then((response) => {
			this.$store.dispatch('updateLoading', false);
			if (response && (response.status === 201 || response.status === 200)) {
				this.directDepositInfo = response.data;
				if (
					typeof this.directDepositInfo.bank === 'undefined' ||
					this.directDepositInfo.bank === null
				) {
					this.editMode = true && this.securityPolicy.updateBanking;
					this.dataExists = false;
				} else {
					this.dataExists = true;
				}
				this.$store.dispatch('clearError');
			} else {
				this.securityPolicy.updateBanking = false;
			}
		});
	}
	get imageInfo() {
		return this.$store.state.i18n.locale === 'en' ? 'cheque-en.svg' : 'cheque-fr.svg';
	}
	saveDirectDepositInfo() {
		this.$store.dispatch('updateLoading', true);
		DirectDepositInfoCall.updateDirectDepositInfo(
			this.directDepositInfo.branch,
			this.directDepositInfo.bank,
			this.directDepositInfo.account,
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$store.state.i18n.locale
		).then((response) => {
			if (response?.status === 201 || response?.status === 200) {
				this.$store.dispatch('updateLoading', false);
				this.editMode = false;
				this.reviewMode = false;
				this.dataExists = true;
				this.$store.dispatch('successBanner', {
					show: true,
					headerText: this.$t('successTitle'),
					text: this.$t('success')
				});
			} else {
				DirectDepositInfoCall.getDirectDepositInfo(
					sessionStorage.getItem('email'),
					sessionStorage.getItem('apiToken'),
					this.$store.state.i18n.locale
				).then((depositInfoResponse) => {
					this.$store.dispatch('updateLoading', false);
					if (depositInfoResponse?.status === 201 || depositInfoResponse?.status === 200) {
						this.directDepositInfo = depositInfoResponse.data;
						if (
							typeof this.directDepositInfo.bank === 'undefined' ||
							this.directDepositInfo.bank === null
						) {
							this.editMode = true && this.securityPolicy.updateBanking;
							this.dataExists = false;
						} else {
							this.dataExists = true;
						}
					} else {
						this.securityPolicy.updateBanking = false;
					}
				});
			}
		});
	}
	closeModal() {
		this.isCancelModalVisible = false;
	}
	cancelDD() {
		this.isCancelModalVisible = true;
	}
	cancelDirectDepositForSure() {
		this.$store.dispatch('updateLoading', true);
		DirectDepositInfoCall.deleteDirectDepositInfo(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$store.state.i18n.locale
		).then((response) => {
			this.$store.dispatch('updateLoading', false);
			if (response && (response.status === 201 || response.status === 200)) {
				this.editMode = true;
				this.reviewMode = false;
				this.dataExists = false;
				this.$store.dispatch('successBanner', {
					show: true,
					headerText: this.$t('successTitle'),
					text: this.$t('success')
				});
				this.isModalVisible = false;
				this.isCancelModalVisible = false;
				this.directDepositInfo = {
					bank: '',
					account: '',
					branch: ''
				};
				this.$store.dispatch('clearError');
			} else {
				this.securityPolicy.updateBanking = false;
			}
		});
	}
	downloadForm() {
		this.$store.dispatch('updateLoading', true);
		var userAgent = navigator.userAgent || navigator.vendor || window.opera;
		// // Have to create this object here because IOS does not allow window.open() inside an async method.
		if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			var windowReference = window.open();
		}
		Forms.getForm(
			sessionStorage.getItem('email'),
			'DD',
			sessionStorage.getItem('apiToken'),
			this.$store.state.i18n.locale
		).then((response) => {
			this.$store.dispatch('updateLoading', false);
			if (response && (response.status === 201 || response.status === 200)) {
				if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
					windowReference.location = response.data;
				} else {
					window.open(response.data, '_blank');
				}
				this.$store.dispatch('clearError');
			}
		});
	} //TODO waiting on apis
}
</script>

<style lang="scss" scoped>
.btn-link {
	font-family: 'Josefin Sans', sans-serif;
}
.update-button {
	font-size: 15px;
	padding-top: 9px;
}
.grey-text {
	font-family: 'Lato', sans-serif;
	font-weight: 300;
	font-size: 17px;
}
ul li {
	margin-bottom: 20px;
}
.image-class {
	width: 300px;
	margin-top: 40px;
	@media (max-width: 767px) {
		margin-top: 10px;
		margin-bottom: 15px;
	}
}
.bold-it-up {
	font-size: 20px;
}
.update-text {
	margin-top: 15px;
	font-size: 16px;
}
.modal_button {
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 0px;
	padding-bottom: 0px;
}
.error-modal ::v-deep .modal-content {
	width: 100%;
}
.error-modal ::v-deep .error-modal-body {
	padding-bottom: 0;
}
.font-size-20 {
	font-size: 20px;
}
.font-size-21 {
	font-size: 21px;
}
.header-class {
	padding-left: 30px;
	padding-top: 30px;
	padding-right: 0px;
	padding-bottom: 0px;
	border: 0px solid transparent;
}
.footer-class {
	padding-left: 30px;
	padding-top: 0px;
	padding-right: 30px;
	padding-bottom: 30px;
	border: 0px solid transparent;
}
.modal-icon {
	font-size: 32px;
	margin-top: 20px;
	margin-bottom: 20px;
	& path {
		color: $error-icon-red;
	}
}
.theBtn {
	float: right;
	font-size: 24px;
	position: absolute;
	top: 0px;
	right: 5px;
	margin: 0;
	padding: 0;
	line-height: 0;
	& path {
		color: $blue;
	}
}

.font-family-jo {
	font-family: 'Josefin Sans', sans-serif;
}
.opt-out {
	margin-top: -10px;
	margin-bottom: 30px;
}
</style>

<!-- *************************************************************************
	TRANSLATIONS
	************************************************************************* -->

<i18n>
{
	"en": {
        "title": {
            "initial": "Direct Deposit",
            "editMode": "Direct Deposit",
            "reviewMode": "Review and Save",
            "subtitle": "Is this the right banking information for you?"
		},
		"successTitle": "Success!",
        "success": "Your banking information has been updated.",
		"breadcrumb": {
			"home": "Home",
			"memberInfo": "Manage your account",
			"directDeposit": "Direct Deposit"
		},
        "imgAlt": "Illustration - how to read a cheque for direct deposit setup",
        "modal": {
            "title" : "Are you sure you want to cancel direct deposit?",
            "paragraph" : "Cancelling direct deposit means you will no longer be able to submit claims online and you won't have claim reimbursements deposited directly into your bank account anymore.",
			"button" : "Cancel Direct Deposit",
			"paragraphTwo" : "Need to change your banking information? ",
			"paragraphThree": "Update it here >"
        },
        "label": {
			"cancelDD": "Opt out of direct deposit",
			"addBankingInfo": "Add your direct deposit banking information",
			"twoWays": "You can add your banking information two ways:",
			"numberOne": "Fill out the short form below and submit it to us.",
			"numberTwo": "Fill in the",
			"numberThree": "Direct Deposit Banking Information Request Form",
			"numberFour": "and mail it to the address on the form.",
			"noCheque": "Don't have a cheque?",
			"whereInfoIs": "You can usually find this information after you’ve logged into your account on your bank’s website. If you can’t find it, you can contact your bank and ask for your direct deposit information. Just a reminder, to take advantage of direct deposit, <b>you’ll need to have a Canadian bank account</b>."
		},
		"noUpdateBankingCard" : {
			"title": "Direct deposit form",
			"subTitle": "If you want to submit your claims online and get reimbursements deposited directly into your bank account, fill out the form below and submit it to your group administrator or human resources representative to get your changes processed.",
			"button": "Direct Deposit Banking Information Request Form"
		}
	},
	"fr": {
        "title": {
            "initial": "Dépôt direct",
            "editMode": "Dépôt direct",
            "reviewMode": "Vérifier et enregistrer",
            "subtitle": "Est-ce que les renseignements bancaires ci-dessous sont exacts?"
				},
				"successTitle": "C’est fait!",
        "success": "Vos renseignements bancaires ont été mis à jour.",
		"breadcrumb": {
			"home": "Accueil",
			"memberInfo": "Gérer votre compte",
			"directDeposit": "Dépôt direct"
		},
        "imgAlt": "Illustration - où trouver les informations pour l'inscription au dépôt direct",
        "modal": {
            "title" : "Voulez-vous vraiment annuler le dépôt direct?",
            "paragraph" : "Si vous annulez le dépôt direct, vous ne pourrez plus soumettre de demande de règlement en ligne et vos remboursements de demande de règlement ne seront plus versés directement dans votre compte bancaire.",
            "button" : "Annuler le dépôt direct",
			"paragraphTwo" : "Vous devez modifier vos renseignements bancaires? ",
			"paragraphThree": "Mettez-les à jour ici >"
        },
        "label": {
			"cancelDD": "Renoncer au dépôt direct",
			"addBankingInfo": "Ajouter vos renseignements bancaires pour le dépôt direct",
			"twoWays": "Vous pouvez ajouter vos renseignements bancaires de deux façons : ",
			"numberOne": "Remplissez le bref formulaire ci-dessous et soumettez-le.",
			"numberTwo": "Remplissez le",
			"numberThree": "formulaire de demande de dépôt direct",
			"numberFour": "et envoyez-le par la poste à l’adresse inscrite sur le formulaire.",
			"noCheque": "Vous n’avez pas de chèque?",
			"whereInfoIs": "Vous trouverez habituellement ces renseignements dans votre compte bancaire en ligne. Si vous ne les trouvez pas, vous pouvez communiquer avec votre banque et lui demander vos renseignements de dépôt direct. Nous vous rappelons que pour utiliser le dépôt direct, <b>vous devez avoir un compte de banque canadien</b>."
        },
		"noUpdateBankingCard" : {
			"title": "Formulaire de demande de dépôt direct",
			"subTitle": "Si vous voulez soumettre vos demandes de règlement en ligne et recevoir vos remboursements directement dans votre compte bancaire, remplissez le formulaire ci-dessous et soumettez-le à votre administrateur de régime ou à votre représentant des Ressources humaines, qui traitera les modifications.",
			"button": "Formulaire de demande de dépôt direct"
		}

	}
}
</i18n>
