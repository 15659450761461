<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->

<template>
	<div class="directDepositCard">
		<div>
			<template v-if="editMode">
				<p class="noLowPadding darker-words">{{ $t('label.branch') }}</p>
				<div class="input-length">
					<BaseInput
						:value="localValue.branch"
						:validation-rules="{ required: true, length: 5, regex: /^[0-9]+$/ }"
						:validation-messages="{
							required: $t('error.branchNumberRequired'),
							length: $t('error.branchNumberLength'),
							regex: $t('error.branchNumberLength')
						}"
						:automation-id="getAutomationId('branchNumber')"
						@input="updateBranch"
					/>
				</div>
				<p class="noLowPadding darker-words">{{ $t('label.bankno') }}</p>
				<div class="input-length">
					<BaseInput
						:value="localValue.bank"
						:validation-rules="{ required: true, length: 3, regex: /^[0-9]+$/ }"
						:validation-messages="{
							required: $t('error.bankNumberRequired'),
							length: $t('error.bankNumberLength'),
							regex: $t('error.bankNumberLength')
						}"
						:automation-id="getAutomationId('bankNumber')"
						@input="updateBank"
					/>
				</div>
				<p class="noLowPadding darker-words">{{ $t('label.accountno') }}</p>
				<div class="input-length">
					<BaseInput
						:value="localValue.account"
						:validation-rules="{ required: true, min: 7, max: 12, regex: /^[0-9]+$/ }"
						:validation-messages="{
							required: $t('error.accountNumberRequired'),
							min: $t('error.accountNumberLength'),
							max: $t('error.accountNumberLength'),
							regex: $t('error.accountNumberLength')
						}"
						:automation-id="getAutomationId('accountNumber')"
						@input="updateAccount"
					/>
				</div>
			</template>
			<template v-else>
				<p class="noLowPadding darker-words">{{ $t('label.branch') }}</p>
				<p class="greytext">{{ localValue.branch }}</p>
				<p class="noLowPadding darker-words">{{ $t('label.bankno') }}</p>
				<p class="greytext">{{ localValue.bank }}</p>
				<p class="noLowPadding darker-words">{{ $t('label.accountno') }}</p>
				<p class="greytext">{{ getMaskedValue() }}</p>
			</template>
		</div>
	</div>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
// This component renders the share Email functionality.
// A member is able to add emails to a list to be sent a member card, or possibly other functionality.
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BaseInput from '@/components/common/base/BaseInput';
import { required, min, max, regex, length } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';
extend('min', min);
extend('max', max);
extend('regex', regex);
extend('required', required);
extend('length', length);

/**
 * This component is responsible to render a member table.
 */
// @vue/component
@Component({
	name: 'DirectDepositInfo',
	components: {
		BaseInput
	},
	mixins: [IdMixin],
	props: {
		value: {
			type: Object,
			default: () => {}
		},
		editMode: {
			type: Boolean,
			default: false
		},
		reviewMode: {
			type: Boolean,
			default: false
		},
		required: {
			type: Boolean,
			default: true
		},
		min: {
			type: Number,
			default: 0
		}
	}
})
export default class DirectDepositInfo extends Vue {
	/**
	 * Returns a locale value to prevent accessing a property from a null object.
	 */
	get localValue() {
		return this.value ? this.value : {};
	}

	getMaskedValue() {
		if (this.reviewMode) {
			return this.localValue.account ? this.localValue.account : '';
		}
		return this.localValue.account ? this.localValue.account.replace(/.(?=.{3,}$)/g, '*') : '';
	}
	/**
	 * Update bank number
	 * @param {string} updateBankNumber The bank number
	 */
	updateBank(bank) {
		this.$emit('input', { ...this.value, bank });
	}
	updateBranch(branch) {
		this.$emit('input', { ...this.value, branch });
	}
	updateAccount(account) {
		this.$emit('input', { ...this.value, account });
	}
}
</script>

<style lang="scss" scoped>
.directDepositCard {
	display: flex;
	@media (max-width: 767px) {
		flex-direction: column;
	}
}
.greytext {
	font-size: 17px;
	font-family: 'Lato', sans-serif;
	font-weight: 300;
}
.noLowPadding {
	margin-bottom: 0px;
}
.darker-words {
	font-size: 17px;
	font-family: 'Josefin Sans', sans-serif;
	color: $black-text;
}
.input-length {
	width: 180px;
}
</style>

<!-- *************************************************************************
	TRANSLATIONS
	************************************************************************* -->

<i18n>
{
  "en": {
    "label": {
      "branch": "Branch/transit number",
      "bankno": "Bank/institution number",
      "accountno": "Account number"
    },
    "error": {
			"bankNumberRequired": "Please enter an institution number.",
      "branchNumberRequired": "Please enter a branch/transit number.",
      "branchNumberLength": "Your branch/transit number should be 5-digits long.",
      "bankNumberLength": "Your institution number should be 3 digits long",
      "accountNumberLength": "Your account number should be between 7-12 digits long.",
      "accountNumberRequired": "Please enter your account number."
    }
  },
  "fr": {
    "label": {
      "branch": "Numéro de transit/de succursale",
      "bankno": "Numéro d’institution/de banque",
      "accountno": "Numéro de compte"
    },
    "error": {
			"bankNumberRequired": "Veuillez entrer le numéro de votre institution financière.",
      "branchNumberRequired": "Veuillez entrer le numéro de succursale/transit.",
      "branchNumberLength": "Le numéro de succursale/transit est composé de 5 chiffres.",
      "bankNumberLength": "Le numéro de l’institution financière est composé de 3 chiffres.",
      "accountNumberLength": "Votre numéro de compte est composé de 7 à 12 chiffres.",
      "accountNumberRequired": "Veuillez entrer votre numéro de compte."
    }
  }
}
</i18n>
