var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"directDepositCard"},[_c('div',[(_vm.editMode)?[_c('p',{staticClass:"noLowPadding darker-words"},[_vm._v(_vm._s(_vm.$t('label.branch')))]),_c('div',{staticClass:"input-length"},[_c('BaseInput',{attrs:{"value":_vm.localValue.branch,"validation-rules":{ required: true, length: 5, regex: /^[0-9]+$/ },"validation-messages":{
						required: _vm.$t('error.branchNumberRequired'),
						length: _vm.$t('error.branchNumberLength'),
						regex: _vm.$t('error.branchNumberLength')
					},"automation-id":_vm.getAutomationId('branchNumber')},on:{"input":_vm.updateBranch}})],1),_c('p',{staticClass:"noLowPadding darker-words"},[_vm._v(_vm._s(_vm.$t('label.bankno')))]),_c('div',{staticClass:"input-length"},[_c('BaseInput',{attrs:{"value":_vm.localValue.bank,"validation-rules":{ required: true, length: 3, regex: /^[0-9]+$/ },"validation-messages":{
						required: _vm.$t('error.bankNumberRequired'),
						length: _vm.$t('error.bankNumberLength'),
						regex: _vm.$t('error.bankNumberLength')
					},"automation-id":_vm.getAutomationId('bankNumber')},on:{"input":_vm.updateBank}})],1),_c('p',{staticClass:"noLowPadding darker-words"},[_vm._v(_vm._s(_vm.$t('label.accountno')))]),_c('div',{staticClass:"input-length"},[_c('BaseInput',{attrs:{"value":_vm.localValue.account,"validation-rules":{ required: true, min: 7, max: 12, regex: /^[0-9]+$/ },"validation-messages":{
						required: _vm.$t('error.accountNumberRequired'),
						min: _vm.$t('error.accountNumberLength'),
						max: _vm.$t('error.accountNumberLength'),
						regex: _vm.$t('error.accountNumberLength')
					},"automation-id":_vm.getAutomationId('accountNumber')},on:{"input":_vm.updateAccount}})],1)]:[_c('p',{staticClass:"noLowPadding darker-words"},[_vm._v(_vm._s(_vm.$t('label.branch')))]),_c('p',{staticClass:"greytext"},[_vm._v(_vm._s(_vm.localValue.branch))]),_c('p',{staticClass:"noLowPadding darker-words"},[_vm._v(_vm._s(_vm.$t('label.bankno')))]),_c('p',{staticClass:"greytext"},[_vm._v(_vm._s(_vm.localValue.bank))]),_c('p',{staticClass:"noLowPadding darker-words"},[_vm._v(_vm._s(_vm.$t('label.accountno')))]),_c('p',{staticClass:"greytext"},[_vm._v(_vm._s(_vm.getMaskedValue()))])]],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }