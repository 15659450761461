<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->

<template>
	<div>
		<template v-if="!editMode">
			<BaseCard
				id="directDepositCard"
				:title="$t('title')"
				title-tag="h2"
				:automation-id="getAutomationId('directDeposit')"
			>
				<p class="subtitle">{{ $t('subtitle') }}</p>
				<DirectDepositInfo
					:value="directDepositInfo"
					:edit-mode="editMode"
					:review-mode="reviewMode"
					:automation-id="getAutomationId('directDepositCard')"
					@input="updateDirectDepositInfo"
				/>
				<template v-if="!viewingMode">
					<template v-if="!reviewMode">
						<template v-if="updateBankingAllowed">
							<div class="mt-2">
								<BaseButton
									:label="$t('label.edit')"
									class="printBtn"
									:aria-label="$t('label.edit')"
									:automation-id="getAutomationId('directDepositCardEdit')"
									@click="openEditView()"
								/>
							</div>
						</template>
					</template>
					<template v-else>
						<div class="mt-4">
							<BaseButton
								:label="$t('label.edit')"
								class="printBtn"
								:aria-label="$t('label.edit')"
								:automation-id="getAutomationId('directDepositCardEdit')"
								@click="openEditView()"
							/>
							<BaseButton
								:label="$t('label.save')"
								class="printBtn"
								variant="primary"
								:aria-label="$t('label.save')"
								:automation-id="getAutomationId('directDepositCardSave')"
								@click="saveDirectDepositInfo()"
							/>
						</div>
					</template>
				</template>
			</BaseCard>
		</template>
		<template v-else>
			<BaseCard
				id="directDepositCardEdit"
				:automation-id="getAutomationId('directDepositEdit')"
				:title="$t('title')"
			>
				<BaseForm
					ref="direct-deposit-form"
					:automation-id="getAutomationId('directDepositForm')"
					@keydown.enter="submit"
					@submit="submitAndReview"
				>
					<BaseAlert class="no-pad" variant="warning" alternate>
						{{ $t('label.friendlyReminder') }}
						<template #description>{{ $t('label.onlyCanada') }}</template>
					</BaseAlert>
					<div class="mt-3">
						<DirectDepositInfo
							:value="directDepositInfo"
							:edit-mode="editMode"
							:review-mode="reviewMode"
							:automation-id="getAutomationId('directDepositCard')"
							@input="updateDirectDepositInfo"
						/>
					</div>
					<div class="pad-bottom">
						<BaseButton
							:label="$t('label.submit')"
							variant="primary"
							:aria-label="$t('label.submit')"
							:automation-id="getAutomationId('directDepositCardSubmit')"
							type="submit"
						/>
						<BaseButton
							v-if="dataExists"
							:label="$t('label.cancel')"
							class="cancel-button"
							:pill="false"
							variant="link"
							:automation-id="getAutomationId('directDepositCardCancel')"
							@click="cancel()"
						/>
					</div>
				</BaseForm>
			</BaseCard>
		</template>
	</div>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
// This component renders Main Member card component with the members details on the card.
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BaseButton from '@/components/common/base/BaseButton';
import BaseForm from '@/components/common/base/BaseForm';
import DirectDepositInfo from '@/components/manage-your-account/direct-deposit/DirectDepositInfo.vue';
import BaseCard from '@/components/common/card/BaseCard';
import BaseAlert from '@/components/common/alert/BaseAlert.vue';

/**
 * This component is responsible to render a member table.
 */
// @vue/component
@Component({
	name: 'DirectDepositCard',
	components: {
		BaseButton,
		BaseForm,
		DirectDepositInfo,
		BaseCard,
		BaseAlert
	},
	mixins: [IdMixin],
	props: {
		directDepositInfo: {
			type: Object,
			default: null
		},
		automationId: {
			type: String,
			default: ''
		},
		editMode: {
			type: Boolean,
			default: false
		},
		reviewMode: {
			type: Boolean,
			default: false
		},
		updateBankingAllowed: {
			type: Boolean,
			default: false
		},
		viewingMode: {
			type: Boolean,
			default: false
		},
		dataExists: {
			type: Boolean,
			default: false
		}
	}
})
export default class DirectDepositCard extends Vue {
	// Emit a edit event to open edit view
	openEditView() {
		this.$emit('edit');
	}
	updateDirectDepositInfo(value) {
		this.$emit('input', value);
	}
	submitAndReview() {
		this.$emit('review');
	}
	cancel() {
		this.$emit('cancel');
	}
	saveDirectDepositInfo() {
		this.$emit('save');
	}
}
</script>

<style lang="scss" scoped>
.title {
	font-size: $header-main-font-size;
	margin-bottom: 0;
	@media (max-width: 500px) {
		line-height: 1.3em;
	}
}
.subtitle {
	font-size: $header-sub-font-size;
	font-family: $body-small-font-family;
	font-weight: $body-small-font-weight;
	margin-bottom: 25px;
}
.printBtn {
	margin-right: 1em;
	@media (max-width: 500px) {
		margin-bottom: 1em;
	}
	& ::v-deep span {
		padding: 1em;
	}
}
.padding-right-5 {
	padding-right: 5px;
}
.padding-left-10 {
	padding-left: 10px;
	line-height: 3;
}
.float-left {
	float: left;
}
.no-pad {
	// Use negative margins to overlap the card padding.
	margin-left: -15px;
	margin-right: -15px;
	@include media-breakpoint-up(md) {
		// There is more padding on cards on larger screens.
		margin-left: -30px;
		margin-right: -30px;
	}
}
.cancel-button {
	@media (max-width: 320px) {
		padding-right: 0px;
	}
}
</style>

<!-- *************************************************************************
	TRANSLATIONS
	************************************************************************* -->

<i18n>
{
	"en": {
        "title": "Direct deposit banking information",
        "subtitle": "This is the account where your claim reimbursements will be deposited.",
        "label": {
			"edit": "Edit",
			"submit": "Submit and Review",
			"cancel": "Cancel",
			"save": "Save",
			"friendlyReminder": "Friendly reminder:",
			"onlyCanada": "we can only accept Canadian bank accounts for direct deposit."
        }
	},
	"fr": {
        "title": "Renseignements bancaires pour le dépôt direct",
        "subtitle": "Les remboursements de demande de règlement seront versés dans le compte ci-dessous.",
        "label": {
			"edit": "Modifier",
			"submit": "Soumettre et vérifier",
			"cancel": "Annuler",
			"save": "Enregistrer",
			"friendlyReminder": "Rappel :",
			"onlyCanada": "nous acceptons uniquement les comptes bancaires canadiens pour le dépôt direct."
        }
	}
}
</i18n>
